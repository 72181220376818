.Contact .contact-info {
  display: flex;
  margin: 20px 0;
}
.contact-info > div {
  flex: 1;
  padding: 0 1rem;
}
.contact-info li {
  list-style: none;
  font-size: 0.9em;
}
.contact-info p {
  font-size: 0.9em;
}
.contact-info a {
  color: #fff;
}

@media screen and (max-width: 768px) {
  .Contact .contact-info {
    flex-direction: column;
  }
  #map {
    width: 100%;
    height: 300px;
  }
}
