.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* max-width: 1500px; */
  margin: 0px auto;
  padding: 20px;
  /* padding: 0.5rem 1rem; */
  /* border: 1px solid red; */
}
.Header a {
  text-decoration: none;
  color: var(--color-secondary);
}
.navigation a {
  /* font-size: 0.85em;
  font-weight: 600; */
  /* padding: 0.5rem 1rem; */
  /* padding-bottom: 2px; */
}
.Header .logo {
  cursor: pointer;
  margin: 10px;
  font-family: "Noto Serif", serif;
  font-weight: 500;
}
.Header .active {
  border-bottom: 1px solid #fff;
  /* text-decoration: underline; */
}
.navigation li {
  display: inline;
  color: var(--color-secondary);
  font-weight: 700;
  text-transform: uppercase;
}
.logo-and-subheader {
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
}
.menu-nav {
  display: flex;
  flex-direction: column;
  text-align: right;
}

/* nav 3.0 */
.navigation {
  display: flex;
  flex-basis: 16%;
  justify-content: space-between;
  max-width: 600px;
}
.nav-item {
  display: inline-block;
  position: relative;
}
.nav-item li {
  font-weight: 600;
  font-size: 0.85em;
  cursor: pointer;
}
.nav-item:hover .sub-menu {
  display: block;
}
.sub-menu {
  position: absolute;
  border: 1px solid #fff;
  background: var(--color-primary);
  z-index: 10;
  display: none;
  left: 0;
  width: fit-content;
}
.sub-menu li {
  display: block;
  padding: 10px;
  white-space: nowrap;
}
.sub-menu li:hover {
  background: var(--color-secondary);
  color: var(--color-primary);
}
.sub-menu a {
  text-decoration: none;
}
.left-hamburger {
  display: none;
}

/* media queries */
@media screen and (max-width: 900px) {
  .navigation {
    flex-basis: 50%;
  }
}
@media screen and (max-width: 768px) {
  .navigation {
    display: none;
  }
  .Header {
    /* display: block; */
    /* position: sticky; */
    /* top: 0;
    left: 0;
    right: 0; */
    margin: 10px auto;
    padding: 0;
  }
  .Header .logo {
    /* display: inline; */
    text-align: center;
    font-size: 1.3em;
  }
  .hamburger-menu {
    /* display: inline-block; */
    justify-content: space-between;
    flex-direction: column;
    margin-right: 18px;
    /* align-items: center; */
  }
  .left-hamburger {
    width: 30px;
    margin-left: 18px;
    display: block;
  }
  .bar {
    /* display: inline-block; */
    width: 30px;
    height: 3px;
    background: var(--color-secondary);
    margin: 5px;
  }
}
