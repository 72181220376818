/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Spectral:wght@200;300;400;500&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap");

:root {
  --color-primary: #051a44;
  --color-secondary: #fff;
}

body {
  margin: 0;
  background-color: var(--color-primary);
  color: var(--color-secondary);
  font-family: "Open Sans", sans-serif;
  /* font-family: "Noto Serif", serif; */
  /* font-family: "Montserrat", "Roboto", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
