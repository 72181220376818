.Team .team-main {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}
.Team .partner {
  flex-basis: 28%;
  /* border: 1px solid red; */
}
.team-main img {
  width: 300px;
}

/* media queries mobile */
@media screen and (max-width: 1000px) {
  .Team .partner img {
    width: 96%;
  }
}
@media screen and (max-width: 858px) {
  .Team .team-main {
    display: block;
  }
  .Team .partner {
    /* flex-basis: 60%; */
    width: 60%;
    margin: 20px auto;
  }
  .Team .partner img {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .Team .partner {
    width: 100%;
    text-align: center;
    margin: 50px 0;
  }
  .partner p {
    text-align: left;
  }
  .Team .partner img {
    width: 80%;
    /* width: 400px; */
  }
}

@media screen and (max-width: 400px) {
  .Team .partner img {
    width: 100%;
    /* width: 400px; */
  }
}
