.Layout .hero {
  width: 100%;
  height: 900px;
  height: 76vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.Layout .cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(24, 61, 137, 0.5);
}
.Layout .content {
  max-width: 1420px;
  margin: 40px auto;
  padding: 20px;
}
.Layout .cover-content {
  max-width: 1200px;
  margin: 20px auto;
  padding: 0 20px;
  text-align: right;
}
.cover-content h2 {
  font-size: 2.2rem;
  font-style: italic;
  font-weight: 500;
}
.content li {
  font-weight: 500;
  margin: 10px 0;
}
.cover-content h3 {
  font-size: 1.6em;
  font-style: italic;
}
.Layout .bottom-line {
  border-bottom: 1px solid #fff;
}

/* media queries  */
@media screen and (max-width: 768px) {
  .cover-content h2 {
    font-size: 2rem;
    text-align: center;
  }
  .cover-content h3 {
    font-size: 1.3em;
    text-align: left;
    /* text-align: center; */
  }
  /* .cover-content h3 {
    font-size: 1.4em;
  } */
  .Layout .cover-content {
    padding: 0 10px;
  }
}
