.Footer {
  display: flex;
  max-width: 1200px;
  justify-content: space-between;
  align-items: center;
  margin: 10px auto;
  font-style: italic;
  padding: 20px;
}
.Footer > * {
  font-size: 0.85em;
  font-weight: 600;
  /* font-style: italic; */
}
.Footer a {
  color: #fff;
  text-decoration: none;
}
.Footer a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .Footer {
    flex-direction: column;
    align-items: center;
  }
  .Footer > * {
    margin: 10px 0;
  }
  .Footer a {
    order: 1;
  }
  .Footer p {
    order: 2;
    text-align: center;
  }
}
