.SelectedManagers {
  background-color: #04102980;
}
.managers-logos {
  /* display: flex; */
  /* flex-wrap: wrap; */
  justify-content: space-between;
  /* margin-top: 20px; */
  /* flex-grow: none; */
  width: 85%;
  margin: 25px auto;
  /* border: 1px solid red; */
  text-align: center;
}
.managers-logos img {
  width: 200px;
  display: inline-block;
  margin: 40px 25px;
  vertical-align: middle;
}
.managers-logos img:hover {
  opacity: 0.8;
}
.managers-logos .cheyne,
.managers-logos .ceecat {
  width: 140px;
  filter: contrast(0.6);
}
.managers-logos .vollou {
  width: 120px;
}
.managers-logos .velocity {
  width: 100px;
}
.managers-logos .diid {
  width: 80px;
}
.managers-logos .bluecedar,
.managers-logos .extantia {
  filter: contrast(0.5);
}
.managers-logos .hanover,
.managers-logos .plasticenergy,
.managers-logos .blow {
  filter: brightness(0) invert(1);
}
