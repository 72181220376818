.MobileNav {
  position: fixed;
  top: 68px;
  top: 0;
  left: 0;
  right: 0;
  /* width: 100%; */
  height: 100%;
  background-color: var(--color-primary);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  padding: 40px;
  margin: 0;
  transition: all 0.3s ease-in-out;
  /* transform: translateX(100%); */
}
.mobile-nav-open {
  transform: translateX(0);
  opacity: 1;
}
.mobile-nav-close {
  transform: translateX(100%);
  opacity: 0;
}
.expand-submenu > p,
.mobile-nav-item > a {
  font-size: 1.2em;
  font-weight: 600;
}
.mobile-nav-submenu a {
  font-size: 1em;
  font-weight: 500;
}
.mobile-nav-submenu {
  display: none;
  /* background-color: #02102c; */
}
.mobile-nav-submenu-open {
  display: block;
}
.mobile-nav-close-btn {
  /* position: absolute; */
  /* top: 0;
  right: 0;
  padding: 10px;
  font-size: 1.5em;
  font-weight: 600; */
  cursor: pointer;
  display: flex;
  /* align-content: center; */
  /* margin-right: 30px; */
  justify-content: flex-end;
  /* justify-items: center; */
}
.expand-submenu {
  display: flex;
  width: 320px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.expand-submenu img {
  width: 12px;
  height: 20px;
}
.expand-submenu p {
  /* margin-bottom: 5px; */
}
.arrow-open {
  transform: rotate(90deg);
}
.mobile-nav-submenu > a > p {
  margin-top: 1px;
}
.mobile-logo {
  cursor: pointer;
  font-family: "Noto Serif", serif;
  font-weight: 500;
}
